<template>
  <div>
    <NavHeader title="个人资料"></NavHeader>

    <div class="content">

      <van-cell-group>
        <van-cell title="头像">
          <van-uploader v-model="fileList" :max-count="1" :after-read="avatarUpload" :before-read="beforeAvatarUpload" slot="right-icon" />
        </van-cell>
        <van-field v-model="name" label="真实姓名" />
        <van-field v-model="nickName" label="昵称" />
        <van-field v-model="idCard" label="身份证" />
        <van-cell title="手机号" :value="mobile" />
        <van-cell title="性别" is-link :value="genderName" @click="genderTap"/>
        <van-cell title="生日" is-link :value="birthday" @click="birthdayTap" />
      </van-cell-group>

      <div class="btn-wrap">
        <van-button type="primary" block @click="submit">保存修改</van-button>
      </div>
    </div>

    <van-popup v-model="showBirthday" position="bottom">
      <van-datetime-picker v-model="currentDate" :min-date="minBirthday" :max-date="maxBirthday" type="date" @confirm="confirmBirthday" :formatter="formatterBirthday" @cancel="cancelBirthday"/>
    </van-popup>

    <van-popup v-model="showGender" position="bottom">
      <van-picker :columns="genderList" :show-toolbar="true" :visible-item-count="3" @confirm="confirmGender" @cancel="cancelGender" :default-index='defaultIndex'/>
    </van-popup>

    <Footer></Footer>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import NavHeader from '@/components/nav-header.vue'
import Footer from '@/components/foot2022.vue'
import utils from '@/common/utils'

export default {
  name:"memberInfo",
  data () {
    return {
      fileList: [],
      imageUrl: "",
      name: "",
      mobile: "",
      gender: "",
      nickName: "",
      birthday: "",
      idCard: "",
      showBirthday: false,
      currentDate: new Date(),
      minBirthday: new Date(1960, 1, 1),
      maxBirthday: new Date(),
      showGender:false,
      genderList: [
        {
          value:1,
          text:"男"
        },
        {
          value:2,
          text:"女"
        },
        {
          value:3,
          text:"保密"
        }
      ],
    }
  },
  components: {
    NavHeader,
    Footer,
  },
  computed: {
    ...mapState(['user_info', 'ocApi', 'access_token']),
    genderName:function() {
      let genderName = ''
      for (const item of this.genderList) {
        if(item.value == parseInt(this.gender)){
          genderName = item.text
        }
      }
      return genderName
    },
    defaultIndex:function(){
      let defaultIndex = 0
      this.genderList.forEach((item,index) => {
        if(parseInt(this.gender) == item.value){
          defaultIndex = index
        }
      })
      return defaultIndex
    },
  },
  created () {
    this.getUserInfo();
    this.$store.dispatch('updateMember');
  },
  methods: {
    getUserInfo () {
      let that = this;

      that.$axios.get(that.ocApi + 'user/select_info', {
        params: {
          'telephone': that.user_info.telephone,
        }
      })
        .then(res => {
          that.imageUrl = res.data.data.avatar
          that.name = res.data.data.username
          that.mobile = res.data.data.mobile
          that.gender = res.data.data.gender + ''
          that.nickName = res.data.data.nickname
          that.birthday = res.data.data.birthday
          that.currentDate = new Date(res.data.data.birthday)
          that.idCard = res.data.data.id_card
          that.fileList = [{ url: res.data.data.avatar }]
        })
        .catch(error => {
          that.$toast.fail(error.response.data.message);
        });

    },
    avatarUpload (e) {
      let that = this;
      let param = new FormData();
      param.append('avatar', e.file);//通过append向form对象添加数据
      that.$axios.post(that.ocApi + "account/me/avatar", param)
        .then(function (res) {
          if (res.data.code == 1) {
            that.$toast.success(res.data.message)
          } else {
            that.$toast.fail(res.data.message)
          }
        })
        .catch(function (error) {
          that.$toast.fail(error)
        })
    },
    beforeAvatarUpload (file) {
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$toast.fail('上传头像图片大小不能超过 2MB!');
      }
      return isLt2M;
    },
    birthdayTap: utils.debounce(function () {
      this.showBirthday = true
    }, 200, true),
    confirmBirthday:function(e) {
      this.birthday = utils.date('Y-m-d',parseInt(e.getTime()/1000))
      this.showBirthday = false
    },
    cancelBirthday:function() {
      this.showBirthday = false
    },
    formatterBirthday: function (type, value) {
      if (type === 'year') {
        return `${value}年`;
      } else if (type === 'month') {
        return `${value}月`
      } else if (type === 'day') {
        return `${value}日`
      }
      return value;
    },
    genderTap: utils.debounce(function () {
      this.showGender = true
    }, 200, true),
    confirmGender:function(e) {
      this.gender = e.value
      this.showGender = false
    },
    cancelGender:function() {
      this.showGender = false
    },
    submit: utils.debounce(function () {
      let that = this;
      that.$axios.post(that.ocApi + 'user/update_info', {
        mobile: that.mobile,
        username: that.name,
        nickname: that.nickName,
        id_card: that.idCard,
        gender: that.gender,
        birthday: that.birthday
      })
        .then(res => {
          if (res.data.code == 1) {
            that.$toast.success('修改成功')
            that.$store.dispatch('updateMember')
            setTimeout(() => {
              that.$router.back()
            }, 1000)
          } else {
            that.$toast.fail(res.data.message);
          }
        })
        .catch(error => {
          that.$toast.fail(error.response.data.message);
        });

    }, 500, true),
  },
}
</script>

<style lang="scss" scoped>
.content {
  padding-top: 46px;
  .btn-wrap{
    width: 100%;
    padding:30px;
    box-sizing: border-box;
  }
}
</style>